import React from 'react';
import PageContainer from '../components/PageContainer';

function ContactPage() {
 
  let pageData = "Blah Blah Blah Blah Blah" 
  
  return (
    <PageContainer pageTitle="Contact Us" pageContent={pageData}>      
    </PageContainer>
  );
}

export default ContactPage;