import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/ProductsPage.css';
import PageContainer from '../components/PageContainer';

function ProductsPage({ pageId }) {
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchQuery = useDebouncedValue(searchInput, 500);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchCategoriesAndTags = async () => {
      try {
        const categoriesResponse = await fetch(`https://eclipse-gear.com/wordpress/wp-json/wp/v2/categories`);
        const tagsResponse = await fetch(`https://eclipse-gear.com/wordpress/wp-json/wp/v2/tags`);

        if (!categoriesResponse.ok || !tagsResponse.ok) {
          throw new Error('Categories or Tags data not found');
        }

        setCategories(await categoriesResponse.json());
        setTags(await tagsResponse.json());
      } catch (err) {
        setError(err.message);
      }
    };

    fetchCategoriesAndTags();
  }, []);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery); // Update the global state when the debounced value changes
  }, [debouncedSearchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let query = `page=${currentPage}&per_page=${productsPerPage}`;
        if (selectedCategory) query += `&categories=${selectedCategory}`;
        if (selectedTag) query += `&tags=${selectedTag}`;
        if (searchQuery) query += `&search=${encodeURIComponent(searchQuery)}`;

        const response = await fetch(`https://eclipse-gear.com/wordpress/wp-json/wp/v2/posts?${query}`);
        setTotalPages(parseInt(response.headers.get('X-WP-TotalPages'), 10));

        if (!response.ok) {
          throw new Error('Products data not found');
        }

        setPageData(await response.json());
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, selectedCategory, selectedTag, searchQuery]);

  const handleCategoryChange = event => {
    setSelectedCategory(event.target.value);
    setCurrentPage(1);
  };

  const handleTagChange = event => {
    setSelectedTag(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = event => {
    setSearchInput(event.target.value); // Update the local state
  };

  const paginate = pageNumber => setCurrentPage(pageNumber);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <PageContainer>
      <div className="filters">
        <div className="filter-item">
          <h3>Categories:</h3>
          <select value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">All Categories</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <h3>Tags:</h3>
          <select value={selectedTag} onChange={handleTagChange}>
            <option value="">All Tags</option>
            {tags.map(tag => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <h3>Search:</h3>
          <input
            type="text"
            placeholder="Search by product name"
            value={searchInput}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div className="products-grid">
        {pageData.map(product => (
          <div className="product-card" key={product.id}>
            <Link to={`/product/${product.id}`} state={{ productData: product.acf.product_group }}>
              <img src={product.acf.product_group.product_image.sizes.medium} alt={product.acf.product_name} className="product-image" />
              <h3 className="product-link">
                {product.acf.product_group.product_name}
              </h3>
            </Link>
            <div dangerouslySetInnerHTML={{ __html: product.acf.product_group.product_description_short }}></div>
          </div>
        ))}
      </div>

      <div className="pagination">
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </PageContainer>
  );
}

// Custom hook for debouncing a value
function useDebouncedValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default ProductsPage;
