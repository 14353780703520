// TermsAndConditionsPage.js

import React,{ useState, useEffect } from 'react';
import PageContainer from '../components/PageContainer';

function TermsAndConditionsPage({ pageId }) {

    const [pageData, setPageData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await fetch(`https://eclipse-gear.com/wordpress/wp-json/wp/v2/pages/${pageId}`);
             
          if (!response.ok ) {
            throw new Error('Data not found');
          }
    
          const data = await response.json();
         
          setPageData(data.acf.page_content);
         
        
        } catch (err) {
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
  }, [pageId]);

  
    
  return (
    <PageContainer htmlContent={pageData}>
    {/* <div>
      <h1>Terms and Conditions</h1>
      <p>...Your terms and conditions content goes here...</p>
    </div> */}
    </PageContainer>
  );
}

export default TermsAndConditionsPage;
