import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Function to extract the code from URL query params
        const getCodeFromLocation = () => {
            return new URLSearchParams(window.location.search).get('code');
        };

        // Function to send code to the backend
        const sendCodeToBackend = async (code) => {
            try {
                const response = await fetch('YOUR_BACKEND_ENDPOINT', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ code }),
                });
                const data = await response.json();
                // Handle response data, e.g., setting user session, redirecting
                console.log(data);
                // Redirect user after successful login
                navigate('/dashboard'); // Updated to use navigate
            } catch (error) {
                console.error('Error:', error);
                // Handle errors, e.g., show message, redirect
                navigate('/login'); // Updated to use navigate
            }
        };

        const code = getCodeFromLocation();
        if (code) {
            // Send code to backend for verification and further processing
            sendCodeToBackend(code);
        } else {
            // No code found in URL, redirect or show an error
            navigate('/login'); // Updated to use navigate
        }
    }, [navigate]); // Add navigate to the dependency array

    return (
        <div>Loading...</div>
    );
};

export default AuthCallback;
