import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';  // Correct named import

const client_id = '1079629580714-fmv4r6u6c97tavgnmsu9d95vv9dpoea7.apps.googleusercontent.com';

const handleGoogleLogin = (response, setUser, onClose) => {
  const userObject = jwtDecode(response.credential);  // Correct usage
  const userData = {
    email: userObject.email,
    name: userObject.name
  };

  // Send the user data to your backend to check/add user
  // fetch('http://localhost:3002/api/login', {
  fetch('https://thawing-river-16467-bec4cb2c31c1.herokuapp.com/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  })
  .then(res => res.json())
  .then(data => {
    console.log(data.message);
    if (data.user) {
      console.log('User exists:', data.user);
      setUser(data.user);
    } else {
      console.log('New user added with ID:', data.userId);
      setUser({ id: data.userId, ...userData });
    }
    onClose();
  })
  .catch(err => {
    console.error('Error:', err);
  });
};

const LoginModal = ({ isOpen, onClose, setUser }) => {
  const [isLoginView, setIsLoginView] = useState(true);

  if (!isOpen) return null;

  return (
    <div className="login-modal">
      <div className="login-modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        {isLoginView ? (
          <div className="social-login">
            <GoogleOAuthProvider clientId={client_id}>
              <GoogleLogin
                onSuccess={(response) => handleGoogleLogin(response, setUser, onClose)}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </GoogleOAuthProvider>
          </div>
        ) : (
          <form>
            {/* Registration form inputs and submit button */}
            <button type="button" onClick={() => setIsLoginView(true)}>
              Go to Login
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
