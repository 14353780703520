import React, { useEffect } from 'react';
import './PageContainer.css';
import Footer from './Footer';


function PageContainer({ pageTitle, pageContent, htmlContent, children }) {
  useEffect(() => {
    // Add any specific logic if needed
  }, []);

  return (
    <div className='container'>
      {pageTitle ? <div className='page-title'>{pageTitle}</div> : <></>}
      {pageContent ? <div className='page-content'>{pageContent}</div> : <></>} 
      {htmlContent ? <div className='page-content'dangerouslySetInnerHTML={{__html: htmlContent}}></div> : <></>}      
      {children ? children : <></>}
      <Footer></Footer>
    </div>
  );
}

export default PageContainer;
