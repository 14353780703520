import React from 'react';
import '../css/HomePage.css';
import Footer from '../components/Footer';

function HomePage() {
  return (
    <div>
      <div className="homepage">
        <div className="video-container">
          <video autoPlay loop muted playsInline>
            <source src="/videos/EngelenMeerDusk.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="overlay">
          <h1>ECLIPSE-GEAR.COM</h1>
          <h3 className="slogan">Illuminate Your Journey</h3>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
