import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Contact Information */}
        <div className="contact-info">
          <h3>Contact Us</h3>
          <p>Email: jtriley@eclipse-gear.com</p>
        </div>

        {/* Navigation Links */}
        <div className="footer-links">
          <h3>Information</h3>
          <ul>
            <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/affiliate-disclosure">Affiliate Disclosure</Link></li>
          </ul>
        </div>

        {/* Copyright Information */}
        <div className="copyright">
          <p>Copyright &copy; {new Date().getFullYear()} ECLIPSE-GEAR.COM. All rights reserved.</p>
        </div>
        <div>
        <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=bcCZzRyi1qXubBcwQPYe0KA1kZ1EkOLmz8cf8fDMWlV3HgcoUzbHPVHZ1RLO"></script></span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
