import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import ProductDetailPage from './pages/ProductDetailPage';
import AboutPage from './pages/AboutPage';  // Assume you have this page
import ContactPage from './pages/ContactPage';  // Assume you have this page
import ProductsPage from './pages/ProductsPage';  // Assume you have this page
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';  // Assume you have this page
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';  // Assume you have this page
import AffiliateDisclosurePage from './pages/AffiliateDisclosurePage';  // Assume you have this page
import AuthCallback from './components/AuthCallback'; // Adjust the import path as necessary


function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/product/:id" element={<ProductDetailPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/products" element={<ProductsPage pageId={12} />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditionsPage pageId={80}/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage pageId={88}/>} />
                <Route path="/affiliate-disclosure" element={<AffiliateDisclosurePage pageId={92} />} />
                <Route path="/auth/callback" element={<AuthCallback />} />
            </Routes>
        </Router>
    );
}

export default App;
