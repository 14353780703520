import React, { useState } from 'react';
import '../css/ProductDetailsPage.css';
import { useParams, useLocation, Link } from 'react-router-dom';
import PageContainer from '../components/PageContainer';

function ProductDetailPage() {
  const { id } = useParams();
  const location = useLocation();
  const productData = location.state?.productData ? location.state.productData : null;

  const [isSpecsExpanded, setIsSpecsExpanded] = useState(false);
  const [isAboutExpanded, setIsAboutExpanded] = useState(false);

  const toggleSpecs = () => {
    setIsSpecsExpanded(!isSpecsExpanded);
  };

  const toggleAbout = () => {
    setIsAboutExpanded(!isAboutExpanded);
  };

  return (
    <PageContainer>
      {productData && (
        <div className='product-container'>
          <h2>Product Detail for {productData.product_name}</h2>
          <div className='image-detail'>
            <div className='image'>
              <img src={productData.product_image.sizes.medium} alt={productData.product_name} className='product-image' />
              <a
                href={productData.product_amazon_affiliate_link}
                className='button'
                target='_blank'
                rel='noopener noreferrer'
              >
                {`Order Now >`}
              </a>
              <Link to='/products' className='button'>{`< Back`}</Link>
            </div>
            <div className='detail'>
             <div className='expandable-section'>
  <h3 onClick={toggleSpecs} className={`expandable-header ${isSpecsExpanded ? 'expanded' : ''}`}>
    Product Specs
    <div className={`expandable-indicator ${isSpecsExpanded ? 'rotate' : ''}`}></div>
  </h3>
  {isSpecsExpanded && (
    <div
      dangerouslySetInnerHTML={{
        __html: productData.product_specifications,
      }}
    ></div>
  )}
</div>
<div className='expandable-section'>
  <h3 onClick={toggleAbout} className={`expandable-header ${isAboutExpanded ? 'expanded' : ''}`}>
    About Product
    <div className={`expandable-indicator ${isAboutExpanded ? 'rotate' : ''}`}></div>
  </h3>
  {isAboutExpanded && (
    <div
      dangerouslySetInnerHTML={{
        __html: productData.product_description_long,
      }}
    ></div>
  )}
</div>              
            </div>
          </div>
          {productData.product_price_amount ? (
            <div className='price-content'>
              <div className='price-amount'>{productData.product_price_amount}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </PageContainer>
  );
}

export default ProductDetailPage;
