import React from 'react';
import '../css/AboutPage.css';
import PageContainer from '../components/PageContainer';

const htmlContent = ('<h3>Our Story</h3>Welcome to Eclipse-Gear.com – your premier destination for the finest sporty and electronic gadgets that truly rise above the rest. Our story began with a simple yet profound vision: to create a haven where people can find hope and inspiration in a world that often feels like a dystopia. Our name, "Eclipse Gear," is a testament to our commitment to overshadowing the difficulties and challenges of our times, providing solutions and products that help you rise above and thrive in the modern world.&nbsp;<h3>Our Community</h3>We are more than just a store; we are a community of like-minded individuals who strive to make the best out of any situation. We believe in the power of superior quality and innovation to light the way in a world that can sometimes feel overwhelming. Our curated selection of products, ranging from the coolest drones to the most advanced sports equipment, are carefully chosen to bring you top-notch performance and style.&nbsp;<h3>Our Features</h3>At Eclipse Gear, we understand the importance of providing our customers with not only the best products but also the best shopping experience. Our user-friendly website and dedicated customer service team are here to support you in your journey, providing you with the gear you need to succeed, no matter what life throws your way. Join us in our mission to rise above the ordinary and embrace the extraordinary. At Eclipse Gear, we are not just surviving; we are thriving, and we want you to do the same.')


function AboutPage() {

  return (
    <PageContainer htmlContent={htmlContent}>
      
    </PageContainer>
  );
}

export default AboutPage;
